@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary: #118c4f;
  --background: #f5f8ff;
}

.primary {
  color: var(--primary);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #333;
}

.container {
  max-width: 1240px;
  margin: auto;
}


body {
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1 {
  font-size: 3.5rem;
  line-height: 1.2;
}

h2 {
  font-size: 2.4rem;
  line-height: 1.2;
}

h5 {
  font-size: 1.1rem;
  line-height: 1.2;
}

p {
  font-size: 1.2rem;
}

p1 {
  color: #118c4f;
}

.btn {
  padding: 14px 32px;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: #fff;
  border-radius: 24px 4px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}

.btn:hover {
  box-shadow: rgb(0 0 0 / 15%) 0px 8px 24px;
}

input {
  padding: 12px 32px;
  border: 1px solid var(--primary);
  background: transparent;
  border-radius: 24px 4px;
  font-size: 1rem;
  margin-right: .8rem;
  font-family: 'Poppins', sans-serif;
}


@media screen and (max-width:768px) {
  h1 {
    font-size: 2.1rem;
    line-height: 1.2;
  }
  
  h2 {
    font-size: 1.5rem;
    line-height: 1.2;
  }
  
  h5 {
    font-size: 1.1rem;
    line-height: 1.2;
  }
  
  p {
    font-size: 1rem;
  }

  .btn {
    width: 100%;
    margin: 1rem 0;
  }

  input {
    width: 100%;
  }

}
