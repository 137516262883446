.hero .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 4rem auto;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: white;
}

.hero .left {
    padding: 1rem;
}

.hero h1 {
    padding: 1rem 0;
}

.hero .input-container {
    padding: 1rem 0;
}

.hero .right {
    padding: 1rem;
}

.hero .img-container {
    width: 110%;
    display: flex;
}

.hero img {
    max-width: 90%;
    margin: auto;
}

.hero .btc {
    color: #118c4f;
}

@media screen and (max-width: 940px) {
    .hero .container {
        grid-template-columns: 1fr;
        margin: 1rem 0;
    }
}











